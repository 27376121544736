import { Runtime, Layout, View, LogViewer, RenderView } from "@brutaljs"
import TodayView        from "./Views/TodayView"
import MealsView        from "./Views/MealsView"
import AuthView         from "./Views/AuthView"
import RecordView       from "./Views/RecordView"
import HistoryView      from "./Views/HistoryView"
import GoalsView        from "./Views/GoalsView"
import CSVExportView    from "./Views/CSVExportView"

import LoggedOutLayout from "./Layouts/LoggedOutLayout"
import LoggedInLayout  from "./Layouts/LoggedInLayout"

const logViewers = [
  new LogViewer({
    type: "measure"
  }),
  new LogViewer({
    logContext: "StellarTrack.Services",
  }),
  /*
  new LogViewer({
    logContext: "StellarTrack.Layouts",
    type: "measure",
  }),
  new LogViewer({
    logContext: "StellarTrack.Views",
    type: "measure",
  }),
  */
  new LogViewer({
    className: [ "Runtime" ],
  })
]
logViewers.forEach( (logViewer) => logViewer.start() )

View.registerView({ TodayView })
View.registerView({ MealsView })
View.registerView({ AuthView })
View.registerView({ RecordView })
View.registerView({ HistoryView })
View.registerView({ GoalsView })
View.registerView({ CSVExportView })

Layout.registerLayout({ logged_in: LoggedInLayout })
Layout.registerLayout({ logged_out: LoggedOutLayout })

const renderView = new RenderView()

window.addEventListener("unhandledrejection", (event) => {
  event.preventDefault()
  console.error(
    "Unhandled Rejection", event
  )
})
window.addEventListener("error", (event) => {
  console.error(
    "Unhandled Error: %o",
    JSON.stringify(event)
  )
  return false
})


document.addEventListener("DOMContentLoaded", () => {
  renderView.render(window)
});
