import { Animator, Component, ClassRegistry, Runtime } from "@brutaljs"
import { Button }                                      from "@brutaljs/Components"

import BaseLayout     from "./BaseLayout"
import Supabase       from "../Services/Supabase"
import Nav            from "./Components/Nav"
import NavCurrentUser from "./Components/NavCurrentUser"
import SignOutLink    from "./Components/SignOutLink"
import Reload         from "./Components/Reload"
import OpenNavButton  from "./Components/OpenNavButton"
import { Session }    from "../Services/Auth"
import AuthView       from "../Views/AuthView"

class LoggedInLayout extends BaseLayout {
  constructor(window) {
    super(window)
    this.registry = new ClassRegistry()
    this.registry.registerClass({ NavCurrentUser })
    this.registry.registerClass({ SignOutLink })
    this.registry.registerClass({ Reload })
    this.registry.registerClass({ Nav })
    this.registry.registerClass({ OpenNavButton })

    this.currentUser = undefined
    this.session = new Session(new Supabase())

    this.requiresLogin = this.document.querySelector("[data-require-login]")
  }

  prepareLayout() {
    return this.session.load().then( (user) => {
      if (user) {
        this.currentUser = user
        this._handleComponents()
        return Promise.resolve(user)
      }
      else {
        if (this.requiresLogin) {
          return AuthView.redirectTo()
        }
        else {
          return Promise.resolve()
        }
      }
    }).catch( (error) => {
      if (error.fromSupabase) {
        return Promise.reject(error)
      }
      else if (this.requiresLogin) {
        return AuthView.redirectTo()
      }
      else {
        return Promise.resolve()
      }
    })
  }

  _handleComponents() {
    this.components = {}
    this.document.querySelectorAll("[data-component]").forEach( (element) => {
      const componentName = element.dataset.component
      const componentClass = this.registry.classFromName(componentName)
      this.components[componentName] = new componentClass(element,this.currentUser)
    })
    this.components.OpenNavButton.onClick( () => {
      this.components.Nav.show()
    })
  }
}
export default LoggedInLayout
