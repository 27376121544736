import { Component }    from "@brutaljs"
import { CSSClassList } from "@brutaljs/JunkDrawer"

export default class TodayTable extends Component {
  constructor(element) {
    super(element)
    this.tbody = this.$selector("tbody")
    this.rowTemplate = this.template()
  }
  addProgress(progress) {
    const measurement = progress.measurement
    const goal = progress.goal
    const numberFormat = Intl.NumberFormat(false,{ notation: "standard", }).format
    const numberFormatForDiff = Intl.NumberFormat(false,{ notation: "standard", signDisplay: "exceptZero" }).format

    const row = this.rowTemplate.newNode({
      fillSlots: {
        name: measurement.metric.name,
        emoji: measurement.metric.emoji,
        goalValue: numberFormat(goal.value),
        currentValue: numberFormat(measurement.value),
        units: measurement.metric.units,
        diff: progress.diff() == 0 ? "" : numberFormatForDiff(progress.diff()),
      }
    })

    row.dataset.metric = goal.metric.name
    let cssClassList
    if (progress.status() == "good") {
      cssClassList = new CSSClassList(row.dataset.cssWhenGood)
    }
    else if (progress.status() == "bad") {
      cssClassList = new CSSClassList(row.dataset.cssWhenBad)
    }
    else {
      cssClassList = new CSSClassList(row.dataset.cssWhenClose)
    }
    cssClassList.addToElement(row)

    this.tbody.appendChild(row)
  }
}

