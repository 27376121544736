import { Form } from "@brutaljs/Form"
import CSVExportFormObject from "./CSVExportFormObject"

export default class CSVExportForm extends Form {
  constructor(element) {
    super(element, new CSVExportFormObject())
    this.proxySubmit()
  }

  setDefaults({from, to}) {
    this.$selector("[name='dateFrom']").value = from.toString()
    this.$selector("[name='dateTo']").value = to.toString()
  }
}


