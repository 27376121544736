import SupabaseError   from "../SupabaseError"
import NoSuchUserError from "./NoSuchUserError"
import BaseService     from "../BaseService"

export default class User extends BaseService {

  signUp(email, password) {
    this.methodStart("signUp")
    return this.supabase.client().auth.signUp({
      email: email,
      password: password,
    }).then( ({data,error}) => {
      if (error) {
        return this.rejectMethod("signUp",new SupabaseError(error))
      }
      return this.linkToUser(data.user).finally( () => {
        return this.resolveMethod("signUp")
      })
    })
  }

  login(email,password) {
    this.methodStart("login")
    return this.supabase.client().auth.signInWithPassword({
      email: email,
      password: password,
    }).then( ({data,error}) => {
      if (error) {
        return this.rejectMethod("login", new SupabaseError(error))
      }
      return this.resolveMethod("login",data.user)
    })
  }

  load(user_id) {
    this.methodStart("load")
    return this.supabase.client().from("users").select("*").eq("id", user_id).then( ({data,error}) => {
      if (error) {
        return this.rejectMethod("load", new SupabaseError(error))
      }
      if (data.length == 0) {
        return this.rejectMethod("load", new NoSuchUserError(user_id))
      }
      return this.resolveMethod("load",data[0])
    })
  }

  linkToUser(authUserFromSupabaseAPI) {
    if (!authUserFromSupabaseAPI) {
      throw "linkToUser requires a user from the supabase API"
    }
    this.methodStart("linkToUser", { email: authUserFromSupabaseAPI.email })
    if (authUserFromSupabaseAPI.user_metadata && authUserFromSupabaseAPI.user_metadata.user_id) {
      return this.load(authUserFromSupabaseAPI.user_metadata.user_id).then( (user) => {
        return this.resolveMethod("linkToUser", authUserFromSupabaseAPI, { alreadyLinked: true })
      })
    }
    return this.supabase.client().from("users").upsert(
      {
        email_address: authUserFromSupabaseAPI.email,
        auth_user_id: authUserFromSupabaseAPI.id
      },
      {
        ignoreDuplicates: false,
        onConflict: "auth_user_id"
      }).select().then( ({data,error}) => {
        if (error) {
          return this.rejectMethod("linkToUser", new SupabaseError(error))
        }
        if (data.length != 1) {
          return this.rejectMethod("linkToUser", new Error(`Expected exactly one user but got ${data.length}`))
        }
        
        const user = data[0]
        return this.supabase.client().auth.updateUser({
          data: { user_id: user.id }
        }).then( ({data,error}) => {
          if (error) {
            return this.rejectMethod("linkToUser", new SupabaseError(error))
          }
          if (!authUserFromSupabaseAPI.user_metadata) {
            authUserFromSupabaseAPI.user_metadata = {}
          }
          authUserFromSupabaseAPI.user_metadata.user_id = user.id
          return this.resolveMethod("linkToUser", authUserFromSupabaseAPI)
        })
        
      })
  }

  rememberCsvExportLastMeasuredOn(user, measuredOn) {
    this.methodStart("rememberCsvExportLastMeasuredOn", { user: user })
    return this.supabase.client().from("users").
      update({ csv_export_last_measured_on: measuredOn }).
      eq("id",user.id).then( ({data,error}) => {
        if (error) {
          return this.rejectMethod("rememberCsvExportLastMeasuredOn",new SupabaseError(error))
        }
        user.csv_export_last_measured_on = measuredOn
        return this.resolveMethod("rememberCsvExportLastMeasuredOn")
      })
  }
}

