import BrutalJSBase from "./BrutalJSBase"

class Env {
  constructor(string) {
    if ( (string === "dev") ||
         (string === "test") ||
         (string === "production") ) {
      this.string = string
    }
    else {
      throw `'${string}' is not a valid ENV`
    }
  }

  isTest()       { return this.string == "test" }
  isDev()        { return this.string == "dev" }
  isProduction() { return this.string == "production" }

  toString() { return this.string }
}
export default class Runtime extends BrutalJSBase {
  static instance() {
    if (!this._instance) {
      this._instance = new Runtime()
    }
    return this._instance
  }
  static env() {
    return this.instance().env
  }
  constructor() {
    super()
    if (typeof ENV !== "undefined") {
      this.env = new Env(ENV)
    }
    else {
      this.env = new Env("dev")
    }
    this.event("runtimeConfigured", { env: this.env.toString() })
  }
}
