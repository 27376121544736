import { Form } from "@brutaljs/Form"

import MeasurementFormObject from "./MeasurementFormObject"
import MeasurementField      from "./MeasurementField"

export default class MeasurementForm extends Form {
  constructor(element) {
    super(element)
    this.proxySubmit()

    this.fieldTemplate   = this.template("metric-field")
    this.fieldsContainer = this.$("fields-container")

  }

  createFields(metrics) {
    const formObject = new MeasurementFormObject(metrics)
    this.measurementFields = metrics.map( (metric) => {
      const field = new MeasurementField(this.fieldTemplate.newNode())
      field.metric = metric
      return field
    })

    this.measurementFields.forEach ( (field) => this.fieldsContainer.appendChild(field.element) )
    this.connectFormObject(formObject)
  }
}

