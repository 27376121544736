import { Component } from "@brutaljs"
import { TextPlaceholder } from "@brutaljs/Components"

class ErrorMessage extends TextPlaceholder {
  set message(validationError) {
    if (validationError.errorMessage) {
      this.text = validationError.errorMessage()
    }
    else {
      this.text = validationError.toString()
    }
  }
}

/** 
 * Component that can render validation errors from a Form.
 *
 * To use:
 *
 * 1. Ensure that your HTML view has something like this in it, that is hidden by default:
 
      <aside role="alert">
        <template>
          <li>
            <slot name="message" />
          </li>
        </template>
        <ul data-form-error-messages>
        </ul>
      </aside>

      <aside>, <li>, and <ul> are not required, but you can see how things will be put together.  Each message
      will be formatted with the <template>, then inserted into the data-form-error-messages element.  Then
      the entire thing will be shown.
 * 2. In the onValidationErrors callback, call showErrors(errors)
 * 3. In the submit callback, call hide()
*/
class FormErrors extends Component {
  constructor(element) {
    super(element)
    this.errorMessagesList = this.$("form-error-messages")
    this.messageTemplate = this.template()
  }

  showErrors(formValidationErrors) {
    this.errorMessagesList.textContent = ""
    formValidationErrors.forEach( (validationError) => {
      const newMessage = this.messageTemplate.newNode()
      const errorMessage = new ErrorMessage(this.$slot("message",newMessage))
      errorMessage.message = validationError
      this.errorMessagesList.appendChild(newMessage)
    })
    this.show()
    this.element.scrollIntoView()
  }
}

export default FormErrors
