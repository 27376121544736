import Metrics from "./Metrics"
import Goal from "../DataTypes/Goal"
import BaseService from "./BaseService"
import SupabaseError from "./SupabaseError"

class Goals extends BaseService {
  constructor(supabase) {
    super(supabase)
    this.goals = []
  }

  saveGoals(newGoals) {
    this.methodStart("saveGoals", { goals: newGoals })
    const rows = newGoals.map( (goal) => {
      return { value: goal.value, metric_id: goal.metric.id }
    })
    return this.supabase.client().from("goals").
      upsert(
        rows,
        {
          ignoreDuplicates: false,
          onConflict: "metric_id"
        }
      ).then( ({data,error}) => {
        if (error) {
          return this.rejectMethod("saveGoals", new SupabaseError(error))
        }
        return this.resolveMethod("saveGoals")
      })
  }

  goalFor(metric) {
    const goal = this.goals.filter( (goal) => goal.metric.id == metric.id )[0]
    if (goal) {
      return goal
    }
    else {
      return new Goal({ metric: metric })
    }
  }

  fetch() {
    this.methodStart("fetch")
    return this.supabase.client().from("goals").
      select(`id, value, metrics(${Metrics.fields()})`).
      then( ({data, error}) => {
        if (error) {
          return this.rejectMethod("fetch", new SupabaseError(error))
        }
        this.goals = data.map( (row) => {
          const metric = Metrics.fromRow(row.metrics)
          return new Goal({...row, ...{ metric: metric }})
        })
        return this.resolveMethod("fetch", this.goals, { goals: this.goals })
      })
  }
}
export default Goals
