import { Component } from "@brutaljs"
import { TextPlaceholder } from "@brutaljs/Components"

class MeasurementFieldInput extends Component {
  set metric(newMetric) {
    this.element.setAttribute("id",   newMetric.name)
    this.element.setAttribute("name", newMetric.name)
  }
  clear() {
    this.element.value = ""
  }
}

class MeasurementFieldLabel extends Component {
  constructor(element) {
    super(element)
    this.nameSlot = new TextPlaceholder(this.$slot("metric-name"))
    this.emojiSlot = new TextPlaceholder(this.$slot("metric-emoji"))
  }
  set metric(newMetric) {
    this.element.setAttribute("for", newMetric.name)

    this.nameSlot.text  = newMetric.name
    this.emojiSlot.text = newMetric.emoji
  }
}
export default class MeasurementField extends Component {
  constructor(element) {
    super(element)
    this.label = new MeasurementFieldLabel(this.$selector("label"))
    this.input = new MeasurementFieldInput(this.$selector("input"))
  }
  set metric(newMetric) {
    this.label.metric = newMetric
    this.input.metric = newMetric
  }

  clear() {
    this.input.clear()
  }
}
