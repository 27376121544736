import { EventManager }  from "@brutaljs"
import { Form }          from "@brutaljs/Form"
import { Button }        from "@brutaljs/Components"
import Metrics           from "../../Services/Metrics"
import MeasurementField  from "../RecordView/MeasurementField"
import NewMealFormObject from "./NewMealFormObject"

export default class NewMealForm extends Form {
  constructor(element, supabase) {
    super(element, new NewMealFormObject([]))
    EventManager.defineEvents(this, "nevermind")
    this.proxySubmit()

    this.supabase = supabase

    this.metrics = new Metrics(supabase)

    this.fieldTemplate   = this.template("metric-field")
    this.fieldsContainer = this.$("fields-container")
    this.nameField = this.$selector("input[name='name']")

    const nevermindButton = new Button(this.$("nevermind-button"))
    nevermindButton.onClick( (event) => {
      this.nevermindEventManager.fireEvent()
      this.reset()
    })
  }

  show() {
    super.show()
    this.nameField.select()

    if (!this.measurementFields) {
      this.metrics.fetch().then( (metrics) => {
        if (metrics.length == 0) {
          return Promise.reject("There were no metrics for the user")
        }
        this._createFields(metrics)
      })
    }
  }

  _createFields(metrics) {
    if (!this.measurementFields) {
      const formObject = new NewMealFormObject(metrics)
      this.measurementFields = metrics.map( (metric) => {
        const field = new MeasurementField(this.fieldTemplate.newNode())
        field.metric = metric
        return field
      })

      this.measurementFields.forEach ( (field) => this.fieldsContainer.appendChild(field.element) )
      this.connectFormObject(formObject)
    }
  }
  reset() {
    if (this.measurementFields) {
      this.measurementFields.forEach( (field) => {
        field.clear()
      })
      this.nameField.value = ""
    }
  }

}

