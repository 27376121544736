import BrutalJSBase from "./BrutalJSBase"

class AnimatorPreferences extends BrutalJSBase {
  constructor() {
    super()
    const prefersReducedMotion = window.matchMedia("(prefers-reduced-motion)").matches
    const documentForcingNoAnimations = document.body.dataset.animatorImmediate === "true"
    this.immediateAlways = documentForcingNoAnimations || prefersReducedMotion
  }
}

export default AnimatorPreferences
