import { EventManager } from "@brutaljs"
import { Form }         from "@brutaljs/Form"
import LoginFormObject  from "./LoginFormObject"

export default class LoginForm extends Form {
  constructor(element) {
    super(element, new LoginFormObject())
    EventManager.defineEvents(this,"login","signUp")
  }

  formSubmitted(event, formObject) {
    if (event.submitter) {
      if (event.submitter.name == "login") {
        this.loginEventManager.fireEvent(formObject)
      }
      else if (event.submitter.name == "signup") {
        this.signUpEventManager.fireEvent(formObject)
      }
      else {
        console.error(`Submitter named ${event.submitter.name} is not supported`)
      }
    }
    else {
      console.error("Odd, no submitter on the event %o",event)
    }
  }
}

