import { Component } from "@brutaljs"
export default class GoalsTable extends Component {
  constructor(element) {
    super(element)
    this.rowTemplate = this.template()
    this.tbody = this.$selector("tbody")
  }

  addOrUpdateRow(goal) {
    const metric = goal.metric
    const row = this.$(`metric='${metric.name}'`,false,() => {
      return null
    })

    const newRow = this.rowTemplate.newNode({
      fillSlots: {
        name: metric.name,
        emoji: metric.emoji,
        units: metric.units,
        valuePerDay: goal.value,
        typeDescription: metric.goalType.description(),
      }
    })
    newRow.dataset.metric=metric.name
    if (goal.value) {
      this.$("when-no-goal",newRow).remove()
    }
    else {
      this.$("when-goal",newRow).remove()
    }
    if (row) {
      row.replaceWith(newRow)
    }
    else {
      this.tbody.appendChild(newRow)
    }
    return newRow
  }
}

