import { Component } from "@brutaljs"

export default class DateSelector extends Component {
  constructor(element) {
    super(element)
    this.dateField = this.$selector("input[type=date]")
  }
  getDate() { return this.dateField.value }
}

