
import { Component } from "@brutaljs"

class Reload extends Component {
  constructor(element) {
    super(element)
    this.element.addEventListener("click", (event) => {
      event.preventDefault()
      window.location.reload()
    })
  }
}

export default Reload
