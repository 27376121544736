import { Component } from "@brutaljs"

import BaseView from "./BaseView"
import Metrics  from "../Services/Metrics"
import Goals    from "../Services/Goals"
import Goal     from "../DataTypes/Goal"

import Supabase        from "../Services/Supabase"
import GoalsForm       from "./GoalsView/GoalsForm"
import GoalsFormObject from "./GoalsView/GoalsFormObject"

const Notice = Component

class GoalsView extends BaseView {

  static url = "/goals.html"

  constructor(element) {
    super(element)

    this.supabase = new Supabase()

    this.metrics = new Metrics(this.supabase)
    this.goals   = new Goals(this.supabase)

    this.form   = new GoalsForm(this.$("goals-form"))
    this.notice = new Notice(this.$("form-notice"))

    this.currentMetrics = []

    this.form.onSubmit( (formObject) => {
      this.notice.hide()
      const newGoals = this.currentMetrics.map( (metric) => {
        const value = formObject[metric.name]

        return new Goal({ metric: metric, value: value})
      })
      this.goals.saveGoals(newGoals).then( () => {
        this.notice.show() 
        this._fetchGoals()
      })
    })
  }

  prepareView() { return this._fetchGoals() }

  _fetchGoals() {
    return this.goals.fetch().then( (goals) => {
      return this.metrics.fetch().then( (metrics) => {
        this.currentMetrics = metrics
        this.currentMetrics.forEach( (metric) => {
          const goal = this.goals.goalFor(metric)
          this.form.addOrUpdateGoalInputRow(goal)
        })
        this.form.connectFormObject(new GoalsFormObject(this.currentMetrics))
        return Promise.resolve()
      })
    })
  }
}

export default GoalsView
