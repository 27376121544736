import { Component } from "@brutaljs"
import { TextPlaceholder } from "@brutaljs/Components"
import Supabase from "../../Services/Supabase"
import { Session } from "../../Services/Auth"

class EmailField extends TextPlaceholder {
  set user(user) {
    this.text = user.email_address
  }
}

class NavCurrentUser extends Component {
  constructor(element, currentUser) {
    super(element)

    this.emailField = new EmailField(this.$slot("currentUser.email"))

    this.session = new Session(new Supabase())

    this.currentUser = currentUser
  }

  set currentUser(newUser) {
    if (newUser) {
      this.emailField.user = newUser
    }
    else {
      this.session.load().then( (user) => {
        this.emailField.user = user
      })
    }
  }
}
export default NavCurrentUser
