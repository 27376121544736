import { CalendarDate } from "@brutaljs/JunkDrawer"
import { AtLeastOneFormValidator, FormObject, } from "@brutaljs/Form"

export default class MeasurementFormObject extends FormObject {
  constructor(metrics) {
    super()
    const metricNames = []
    metrics.forEach( (metric) => {
      this.field(metric.name)
      metricNames.push(metric.name)
    })
    this.field("measuredOn", { defaultValue: CalendarDate.today().toString() })
    this.addCustomValidation(new AtLeastOneFormValidator(metricNames))
  }
}
