import { Component } from "@brutaljs"
import Measurement   from "../../DataTypes/Measurement"

export default class HistoryTable extends Component {
  constructor(element) {
    super(element)

    this.tbody = this.$selector("tbody")

    this.firstRowTemplate = this.template("row='first'")
    this.restRowTemplate  = this.template("row='rest'")
  }

  _groupMeasurementsByDate(measurements) {
    return measurements.reduce( (grouped,measurement) => {
      if (!grouped[measurement.measuredOn]) {
        grouped[measurement.measuredOn] = []
      }
      grouped[measurement.measuredOn].push(measurement)
      grouped[measurement.measuredOn].sort(Measurement.compare)
      return grouped
    }, {})
  }

  set measurements(measurements) {
    Object.entries(this._groupMeasurementsByDate(measurements)).forEach( ([measuredOn,measurements]) => {
      if (measurements.length > 0) {
        measurements.forEach( (measurement, index, array) => {

          let row
          const fillSlots = {
            name: measurement.metric.name,
            value: measurement.value,
            units: measurement.metric.units,
            emoji: measurement.metric.emoji,
          }

          if (index == 0) {
            row = this.firstRowTemplate.newNode({
              fillSlots: { ...fillSlots, ...{measuredOn: measuredOn} }
            })
            const measuredOnColumn = this.$("measured-on",row)
            measuredOnColumn.setAttribute("rowspan",array.length)
          }
          else {
            row = this.restRowTemplate.newNode({ fillSlots: fillSlots })
          }
          row.setAttribute("data-measured-on",measuredOn)
          this.tbody.appendChild(row)
        })
      }
    })
  }
}
