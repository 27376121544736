import BrutalJSBase        from "./BrutalJSBase"
import HumanizedString     from "./JunkDrawer/HumanizedString"
import TypeOf              from "./JunkDrawer/TypeOf"
import EventAlreadyDefined from "./EventAlreadyDefined"

class EventDefinition {
  constructor(object, name) {
    this.object           = object
    this.name             = name
    this.eventManagerName = `${name}EventManager`
    this.addListenerName  = `on${HumanizedString.for(name)}`

    if (object[this.eventManagerName]) {
      throw new EventAlreadyDefined(name,this.eventManagerName)
    }
    if (object[this.addListenerName]) {
      throw new EventAlreadyDefined(name,this.addListenerName)
    }
  }

  create() {
    const eventManager = new EventManager(this.object,this.name)
    this.object[this.eventManagerName] =  eventManager
    this.object[this.addListenerName] = (listener) => {
      eventManager.addListener(listener)
    }
    return eventManager
  }
}

/*
 * Basic publish/subscribe class for modeling events and managing listeners.
 *
 * A core concept of the library is that your code should not rely on the
 * builtin EventTarget or Event APIs, as they are quite low level.  Rather,
 * your components would model explicit events such as `onClick` or
 * `onSubmit`.  The `EventManager` is provided to facilitate the implementation
 * of those events.
 *
 * The long-form of using this class might be as follows:
 *
 *     class Button extends Component {
 *       constructor(element) {
 *         super(element)
 *         this.clickEventManager = new EventManager(this,"click")
 *         this.element.addEventListener("click", () => {
 *           this.clickEventManager.fireEvent()
 *         })
 *       }
 *
 *       onClick(listener) { this.clickEventManager.addListener(listener) }
 *     }
 *
 * There are convienince methods for reducing this boilerplate.
 */
class EventManager extends BrutalJSBase {

  /** Shortcut to creating event managers explcitly.
   *
   * Ideally used in the constructor of your class to define all
   * the events that your class will broadcast.
   *
   * object:: the object on which the events will be defined
   * names:: a list of names of events.  For each name, this method will define an EventManager
   *         for that named event, and then a method on«name» (where name is upper-case camelized)
   *         that will register the passed listener for that event.  To fire an event, use
   *         the EventNamager.
   *
   *         For example, a name like "formSubmitted" would create the method `onFormSubmitted` which
   *         you could trigger by calling `this.formSubmittedEventManager.fireEvent()`
   */
  static defineEvents(object, ...names) {
    names.map( (name) => {
      return new EventDefinition(object,name)
    }).forEach( (eventDefinition) => {
      eventDefinition.create()
    })
  }

  constructor(object, eventName) {
    super()
    this.listeners = new Set()
    this.eventName = eventName
    this.objectClass = TypeOf.asString(object)
  }

  /** Adds a listener to this event. The listener should be a function that will be given whatever arguments are
   * given to fireEvent */
  addListener(listener) { this.listeners.add(listener) }

  /** Remove a listener */
  removeListener(listener) { return this.listeners.delete(listener) }

  /** Fire the event, passing each listener the given args */
  fireEvent(...args) {
    this.methodStart("fireEvent", { listeners: this.listeners.size, eventName: this.eventName, objectClass: this.objectClass })
    this.listeners.forEach( (listener) => {
      listener(...args)
    })
    this.methodDone("fireEvent")
  }

  static createDirectProxyFor(object,{element,eventName,options={}}) {
    const eventDefinition = new EventDefinition(object,eventName)
    const eventManager = eventDefinition.create()
    element.addEventListener(eventName, (event) => {
      event.preventDefault()
      eventManager.fireEvent()
    })
  }

}

export default EventManager
