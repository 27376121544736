import Log from "./Log"

/**
 * One base class to rule them all. This was created to automatically add
 * the log mixing to all lib classes where relevant plus set the log context appropriately.
 */
export default class BrutalJSBase {
  static logContext = "@brutaljs"
  constructor() {
    Log.mixin(this.constructor)
  }
}
