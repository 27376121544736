import SupabaseError   from "../SupabaseError"
import NoSessionError  from "./NoSessionError"
import User            from "./User"
import BaseService     from "../BaseService"

export default class Session extends BaseService {
  constructor(supabase) {
    super(supabase)
    this.user = new User(supabase)
  }

  load() {
    this.methodStart("load")
    return this.supabase.client().auth.getSession().then( ({data, error}) => {
      if (error) {
        return this.rejectMethod("load", new SupabaseError(error))
      }
      if (!data.session) {
        return this.resolveMethod("load", null)
      }
      const authUserFromSupabaseAPI = data.session.user
      return this.user.linkToUser(authUserFromSupabaseAPI).then( (updatedAuthUserFromSupabaseAPI) => {
        return this.user.load(updatedAuthUserFromSupabaseAPI.user_metadata.user_id)
      }).catch( (error) => {
        return this.rejectMethod("load", error)
      })
    })
  }

  logOut() {
    this.methodStart("logOut")
    return this.supabase.client().auth.signOut().then( ({error}) => {
      if (error) {
        return this.rejectMethod("logOut", new SupabaseError(error))
      }
      return this.resolveMethod("logOut")
    })
  }
}
