import { CalendarDate } from "@brutaljs/JunkDrawer"

import EphemeralMeasurement from "../DataTypes/EphemeralMeasurement"
import Goal                 from "../DataTypes/Goal"
import Progress             from "../DataTypes/Progress"
import BaseService          from "./BaseService"
import Goals                from "./Goals"
import MeasurementsPerDay   from "./MeasurementsPerDay"
import NoGoalsError         from "./NoGoalsError"
import SupabaseError        from "./SupabaseError"

class Summary {
  constructor(goals, fromCalendarDate, toCalendarDate) {
    let numDays = 1
    if (toCalendarDate) {
      numDays = fromCalendarDate.daysDiff(toCalendarDate)
    }
    this.summary = {}
    goals.forEach( (goal) => {
      this.summary[goal.metric.name] = new Progress({
        numDays: numDays,
        goal: new Goal({metric: goal.metric, value: goal.value}),
        measurement: new EphemeralMeasurement({ value: 0, metric: goal.metric})
      })
    })
  }

  updateMeasurement(measurement) {
    const metric = measurement.metric
    if (!this.summary[metric.name]) {
      throw `updateMeasurement called for metric '${metric.name}', but there is no Goal for that metric`
    }
    this.summary[metric.name].measurement.value = this.summary[metric.name].measurement.value + measurement.value
  }

  forEach(f) {
    Object.values(this.summary).sort(Progress.compare).forEach( (progress) => f(progress) )
  }
}

export default class ProgressOverPeriod extends BaseService {
  constructor(supabase, fromCalendarDate, toCalendarDate) {
    super(supabase)

    this.fromCalendarDate = fromCalendarDate || CalendarDate.today()
    this.toCalendarDate   = toCalendarDate

    this.goals              = new Goals(supabase)
    this.measurementsPerDay = new MeasurementsPerDay(supabase)
    this.summary            = null
  }

  calculate() {
    this.methodStart("calculate")
    return this.goals.fetch().then( (goals) => {
      if (goals.length == 0) {
        return this.rejectMethod("calculate",new NoGoalsError())
      }
      const summary = new Summary(goals, this.fromCalendarDate, this.toCalendarDate)
      return this.measurementsPerDay.fetch(this.fromCalendarDate, this.toCalendarDate).then( (measurements) => {
        measurements.forEach( (measurement) => {
          summary.updateMeasurement(measurement)
        })
        return this.resolveMethod("calculate", summary, { summary: summary })
      })
    })
  }
}
