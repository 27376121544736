export default class EphemeralMeasurement {
  constructor({value,metric}) {
    this.value = value
    this.metric = metric
  }

  static compare(a,b) {
    if (a.metric.name == b.metric.name) {
      return a.metric.id - b.metric.id
    }
    else if (a.metric.name > b.metric.name) {
      return 1
    }
    else {
      return -1
    }
  }
}
