import { Component, EventManager, View } from "@brutaljs"
import MarkDownloadedButton from "./MarkDownloadedButton"

export default class Download extends Component {
  constructor(element) {
    super(element)
    this.link = this.$selector("a")
    this.rememberedMessage = new Component(this.$("remembered"))
    this.markDownloaded = new MarkDownloadedButton(this.$("mark-downloaded"))
    this.markDownloaded.onClick( () => {
      this.markDownloadedEventManager.fireEvent(this.markDownloaded.dateToRemember)
    })
    EventManager.defineEvents(this, "markDownloaded")
  }

  setCSV({from, to, data}) {
    const filename = `StellarTrack-${from.toString()}--${to.toString()}.csv`
    const csv = data.map( (row) => {
      return row.join(",")
    }).join("\n")
    const encodedCSV = encodeURIComponent(csv)
    this.link.download = filename
    this.link.href = `data:attachment/csv,${encodedCSV}`
    this.markDownloaded.date = to
    this.rememberedMessage.hide()
    this.markDownloaded.show()
    this.show()
  }

  remembered() {
    this.markDownloaded.hide()
    this.rememberedMessage.show()
  }
}
