import { FormObject } from "@brutaljs/Form"

export default class SearchFormObject extends FormObject {
  constructor() {
    super()
    this.field("q")
  }

  set formData(formData) {
    this.q = `${formData.get("food")} nutrition information`
  }
}
