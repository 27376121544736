import EphemeralMeasurement from "./EphemeralMeasurement"

export default class Measurement extends EphemeralMeasurement {
  constructor({id,value,measuredOn,metric,meal}) {
    super({value,metric})
    this.id = id
    this.measuredOn = measuredOn
    this.measuredOnDate = new Date(measuredOn)
    this.mealId = meal ? meal.id : undefined
  }

  measuredOnTime() { return this.measuredOnDate.getTime() }

  static compare(a,b) {
    if (a.measuredOn && b.measuredOn) {
      if (a.measuredOnTime() === b.measuredOnTime()) {
        return EphemeralMeasurement.compare(a,b)
      }
      else {
        return a.measuredOnTime() - b.measuredOnTime()
      }
    }
    else {
      return EphemeralMeasurement.compare(a,b)
    }
  }
}

