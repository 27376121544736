import BrutalJSBase from "./BrutalJSBase"
/*
 * Base class for wrapping a DOM element to provide logic.  This is the core class of the library and 
 * provides a few convienience methods useful when interacting with the DOM.
 *
 * In general, you likely want to use Component or View.
 */
class WrapsElement extends BrutalJSBase {
  constructor(element) {
    super()
    this.element = element
  }

  /** Access an element relvative to this component's element based on
   *  a selector as you'd use for querySelectorAll() or querySelector()
   *
   *  It is expected to return exactly one element and will blow up if it does not
   *  locate exactly one.
   *
   *  selector:: A selector compatible wiht querySelectorAll or querySelector
   *  baseElement:: if omitted, uses this component's element. If given, uses it
   *                as the basis for the search.
   *  whenNotFound:: if provided, will be called if no elements matching the selector
   *                 are found. No arguments given.
   *  whenMultipleFound:: if provided, will be called if more than one element matching
   *                      the selector is found. Given an argument with the number of elements found.
   *                  
   *  Returns the element located.
   *
   *  If exactly on element is not found, by default an error is thrown. You can
   *  customize this behavior by providing `whenNotFound` and/or `whenMultipleFound`.
   */
  $selector(selector,baseElement,whenNotFound,whenMultipleFound) {
    if (!baseElement) {
      baseElement = this.element
    }
    if ( !(baseElement instanceof Element) && !(baseElement instanceof Document) ) {
      throw `Base element should be an Element or Document. Got ${JSON.stringify(baseElement)}`
    }
    if (!whenNotFound) {
      whenNotFound = () => {
          throw `Could not find '${selector}' from ${baseElement.outerHTML}`
      }
    }
    if (!whenMultipleFound) {
      whenMultipleFound = (numFound) => {
        throw `Found ${numFound} nodes instead of 1 matching '${selector}' from ${baseElement.outerHTML}`
      }
    }

    const elements = baseElement.querySelectorAll(selector)

    if (elements.length == 1) {
      return elements.item(0);
    }
    else if (elements.length == 0) {
      return whenNotFound()
    }
    else {
      return whenMultipleFound(elements.length)
    }
  }

  /** Access elements relvative to this component's element based on
   *  a selector as you'd use for querySelectorAll(), but with the requirement
   *  that at least one element be found.
   *
   *  selector:: A selector compatible wiht querySelectorAll or querySelector
   *  baseElement:: if omitted, uses this component's element. If given, uses it
   *                as the basis for the search.
   *                  
   *  Returns the elements located or raises an error if no elements matched.
   */
  $selectors(selector,baseElement) {
    if (!baseElement) {
      baseElement = this.element
    }

    const elements = baseElement.querySelectorAll(selector)
    if (elements.length == 0) {
      throw `Expected ${selector} to return 1 or more elements, but matched 0 from ${baseElement}`
    }
    return elements;
  }

  /** Calls $selector with `[data-${dataAttribute}]` */
  $(dataAttribute,baseElement,whenNotFound) { 
    return this.$selector(`[data-${dataAttribute}]`,baseElement, whenNotFound)
  }
  
  /** Calls $selector with `[slot=name='${slotName}']` */
  $slot(slotName,baseElement,whenNotFound) { 
    return this.$selector(`slot[name='${slotName}']`,baseElement, whenNotFound)
  }

  /** Calls $selectors with `slot[name='${slotName}']` */
  $slots(slotName,baseElement) { 
    return this.$selectors(`slot[name='${slotName}']`,baseElement)
  }

}
export default WrapsElement
