import { CalendarDate }  from "@brutaljs/JunkDrawer"

import Measurement   from "../DataTypes/Measurement"
import Metric        from "../DataTypes/Metric"
import BaseService   from "./BaseService"
import Metrics       from "./Metrics"
import SupabaseError from "./SupabaseError"

export default class Measurements extends BaseService {
  constructor(supabase) {
    super(supabase)
    this.metrics = new Metrics(supabase)
  }

  fetch({from, to}) {
    if ( (!from && to) || (!to && from) ) {
      throw "Measurements.fetch requires either both from and to or neither"
    }
    this.methodStart("fetch", { from: from, to: to })
    let query = this.supabase.client().from("measurements").select(`
      id,
      measuredOn:measured_on,
      value,
      metrics (${Metrics.fields()})
    `)
    if (from) {
      query = query.gte("measured_on",from.toString()).lte("measured_on",to.toString())
    }
    return query.order("measured_on", { ascending: false }).then( ({data,error}) => {
      if (error) {
        return this.rejectMethod("fetch",new SupabaseError(error))
      }
      const measurements = data.map( (row) => {
        const metricRow = row.metrics
        const metric = Metrics.fromRow(metricRow)
        return new Measurement({...row, ...{ metric: metric }})
      })
      return this.resolveMethod("fetch",measurements,{ measurements: measurements })
    })
  }

  saveMeal(meal, measuredOn) {
    this.methodStart("saveMeal", { meal: meal, measuredOn: measuredOn })
    const measurements = meal.ephemeralMeasurements.map( (ephemeralMeasurement) => {
      return new Measurement({
        metric: ephemeralMeasurement.metric,
        value: ephemeralMeasurement.value,
        measuredOn: measuredOn,
        meal: meal,
      })
    })
    return this.save(measurements)
  }

  save(measurements) {
    if (measurements instanceof Measurement) {
      measurements = [measurements]
    }
    this.methodStart("save", { measurements: measurements })
    const insert = measurements.map( (measurement) => {
      return {
        metric_id: measurement.metric.id,
        value: measurement.value,
        measured_on: measurement.measuredOn,
        meal_id: measurement.mealId
      }
    })

    return this.supabase.client().from("measurements").insert(insert).then( ({error}) => {
      if (error) {
        return this.rejectMethod("save", new SupabaseError(error))
      }
      return this.resolveMethod("save")
    })
  }
}
