import { View  }             from "@brutaljs"
import { Button, TextField } from "@brutaljs/Components"

import Supabase          from "../Services/Supabase"
import SupabaseError     from "../Services/SupabaseError"
import { Session, User } from "../Services/Auth"
import BaseView          from "./BaseView"
import LoginForm         from "./AuthView/LoginForm"
import ErrorMessagePanel from "./AuthView/ErrorMessagePanel"
import TodayView         from "./TodayView"

class AuthView extends BaseView {

  static url = "/auth.html"

  constructor(element) {
    super(element)

    this.form               = new LoginForm(this.$selector("form"))
    this.errorMessagesPanel = new ErrorMessagePanel(this.$("error-messages"))

    this.supabase = new Supabase()
    this.session  = new Session(this.supabase)
  }

  prepareView() {

    const user = new User(this.supabase)
    const handleError = (error) => {
      if (error.fromSupabase) {
        this.errorMessagesPanel.showErrors(error.message)
      }
      else {
        this.errorMessagesPanel.showErrors(`Sorry, it looks like the app has a bug: ${error.message}`)
        throw error
      }
    }

    this.form.onLogin( ({email,password}) => {
      user.login(email,password).then( () => TodayView.redirectTo()).catch( (error) => {
        handleError(error)
      })
    })

    this.form.onSignUp( ({email,password}) => {
      user.signUp(email,password).then( () => TodayView.redirectTo()).catch( (error) => {
        handleError(error)
      })
    })

    return this.session.load().then( (user) => {
      if (user) {
        TodayView.redirectTo()
      }
    })
  }
}

export default AuthView
