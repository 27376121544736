class GoalType {
  static allowedTypes = {
    at_most: {
      id: "at_most",
      description: "at most",
    },
    at_least: {
      id: "at_least",
      description: "at least",
    },
  }
  constructor(value) {
    this.type = GoalType.allowedTypes[value]
    if (!this.type) {
      throw `No such GoalType '${value}'`
    }
  }

  whenAtMost(f) {
    if (this.type.id == "at_most") {
      f()
    }
    return this
  }
  whenAtLeast(f) {
    if (this.type.id == "at_least") {
      f()
    }
    return this
  }

  description() { return this.type.description }

}
class Metric {
  constructor({id, name, units, emoji, goalType, goal_type}) {
    this.id    = id
    this.name  = name
    this.units = units
    this.goalType = new GoalType(goalType || goal_type)
    this.emoji = emoji || ""
  }
}

export default Metric
