import { Component, Runtime, View } from "@brutaljs"
import { CalendarDate }             from "@brutaljs/JunkDrawer"

import BaseView            from "./BaseView"
import { Session  }        from "../Services/Auth"
import Metrics             from "../Services/Metrics"
import NoGoalsError        from "../Services/NoGoalsError"
import ProgressOverPeriod  from "../Services/ProgressOverPeriod"
import Supabase            from "../Services/Supabase"
import TodayTable          from "./TodayView/TodayTable"
import RunningAverageTable from "./TodayView/RunningAverageTable"
import GoalsView           from "./GoalsView"

const LoadingIndicator = Component

class TodayView extends BaseView {

  static url = "/index.html"

  constructor(element) {
    super(element)

    this.todayTable     = new TodayTable(this.$("today"))
    this.pastSevenTable = new RunningAverageTable(this.$("past-seven"))
    this.pastMonthTable = new RunningAverageTable(this.$("past-month"))

    this.supabase          = new Supabase()
    this.session           = new Session(this.supabase)
    this.metrics           = new Metrics(this.supabase)
    this.progressToday     = new ProgressOverPeriod(this.supabase)
    this.progressPastSeven = new ProgressOverPeriod(this.supabase, CalendarDate.daysAgo(7), CalendarDate.today())
    this.progressPastMonth = new ProgressOverPeriod(this.supabase, CalendarDate.daysAgo(28), CalendarDate.today())
  }

  prepareView(user) {
    const userId = user.id

    return this.progressToday.calculate().then( (summary) => {
      summary.forEach( (progress) => {
        this.todayTable.addProgress(progress)
      })
      this.progressPastSeven.calculate().then( (summary) => {
        summary.forEach( (progress) => {
          this.pastSevenTable.addProgress(progress)
        })
      })
      this.progressPastMonth.calculate().then( (summary) => {
        summary.forEach( (progress) => {
          this.pastMonthTable.addProgress(progress)
        })
      })
    }).catch( (error) => {
      if (error instanceof NoGoalsError) {
        this.event("noGoals")
        return this.metrics.initialize(userId).then( () => {
          return GoalsView.redirectTo()
        })
      }
      else {
        return Promise.reject(error)
      }
    })
  }
}

export default TodayView
