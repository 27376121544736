export default class Meal {
  constructor({id, userId, name,ephemeralMeasurements}) {
    this.id = id
    this.userId = userId
    this.name = name
    this.ephemeralMeasurements = ephemeralMeasurements
  }

  static compare(a,b) {
    if (a.name == b.name) {
      return 0
    }
    else if (a.name > b.name) {
      return 1
    }
    else {
      return -1
    }
  }
}
