import { BrutalJSBase } from "@brutaljs"

class BaseService extends BrutalJSBase {

  static logContext = "StellarTrack.Services"

  constructor(supabase) {
    super() 
    this.supabase = supabase
  }

  rejectMethod(methodName,error,options = {}) {
    const details = {
      error: true,
      errorMessage: error.message
    }
    if (options.notError) {
      delete details.error
    }
    this.methodDone(methodName, details)
    return Promise.reject(error)
  }
  resolveMethod(methodName,returnValue,details) {
    this.methodDone(methodName,details)
    return Promise.resolve(returnValue)
  }
}
export default BaseService
