import { Component, EventManager } from "@brutaljs"
import { Button }                  from "@brutaljs/Components"
import EphemeralMeasurement        from "../../DataTypes/EphemeralMeasurement"

export default class MealRow extends Component {
  constructor(element, meal) {
    super(element)
    EventManager.defineEvents(this,"mealRecorded")
    this.meal = meal
    this.metricsList = this.$("metrics")
    this.metricsTemplate = this.template(false, this.metricsList)
    this.recordButton = new Button(this.$selector("button[type=submit]"))
    // XXX: Make this better
    this.recordButton.element.ariaLabel = `Record ${meal.name}`
    this.meal.ephemeralMeasurements.sort(EphemeralMeasurement.compare).forEach( (ephemeralMeasurement) => {
      const element = this.metricsTemplate.newNode({
        fillSlots: {
          emoji: ephemeralMeasurement.metric.emoji,
          units: ephemeralMeasurement.metric.units,
          value: ephemeralMeasurement.value,
        }
      })
      this.metricsList.appendChild(element)
    })
    this.recordButton.onClick( () => this.mealRecordedEventManager.fireEvent(this.meal) )
  }
}

