import BaseError from "./BaseError"

/** 
 * Used to wrap errors from Supabase that we don't handle
 * to allow the downstream
 * code to understand that this is not a known error message
 */
export default class SupabaseError extends BaseError {
  constructor(errorFromSupabase) {
    super(errorFromSupabase.message)
    this.errorFromSupabase = errorFromSupabase
    this.fromSupabase = true
  }
}

