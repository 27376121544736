import TypeOf from "./JunkDrawer/TypeOf"

const LogMixin = {

  /** Used to log the start of a method for later measurement of the method's duration */
  methodStart(methodName,details) {
    this.event(`${methodName}#called`,details)
  },
  /** Used to log the end of a method. You must have called methodStart. This will
   * record an event and a measurement
   */
  methodDone(methodName,details) {
    this.event(`${methodName}#completed`,details)
    this.measure(methodName,`${methodName}#called`,`${methodName}#completed`)
  },
  event(eventName,details) {
    details = {...this.__defaultDetails(), ...details }
    performance.mark(this._name(eventName),{ detail: details })
    this.__logMixinDetails = {...this.__logMixinDetails, ...details}
  },
  measure(measurementName,start,end) {
    if (!start) {
      throw `You forgot to add start & end to measure(${measurementName})`
    }
    else if (!end) {
      throw `You forgot to add end to measure(${measurementName})`
    }

    performance.measure(this._name(measurementName),
      {
        start: this._name(start),
        end: this._name(end),
        detail: this.__logMixinDetails 
      }
    )
  },
  _name(eventName) {
    if ( (this.constructor) && (this.constructor.logContext) ) {
      return `${this.constructor.logContext}.${this.__className()}.${eventName}`
    }
    else {
      return `${this.__className()}.${eventName}`
    }
  },
  __defaultDetails() {
    const details = {
      logContext: this.constructor.logContext || "UNKNOWN",
      className: this.__className(),
    }
    if (window && window.location) {
      details[`window.location.pathname`] = window.location.pathname
    }
    return details
  },
  __className() {
    return TypeOf.asString(this)
  },
  mixin(klass) {
    Object.assign(klass.prototype,LogMixin)
  }
}
export default LogMixin
