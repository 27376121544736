import { View } from "@brutaljs"
import { CalendarDate } from "@brutaljs/JunkDrawer"

import BaseView      from "./BaseView"
import Measurements  from "../Services/Measurements"
import Measurement   from "../DataTypes/Measurement"
import Supabase      from "../Services/Supabase"
import HistoryTable  from "./HistoryView/HistoryTable"

class HistoryView extends BaseView {

  static url = "/history.html"

  constructor(element) {
    super(element)

    this.supabase = new Supabase()
    this.measurements = new Measurements(this.supabase)

    this.table = new HistoryTable(this.$("history"))
  }

  prepareView() {
    return this.measurements.fetch({ from: CalendarDate.daysAgo(7), to: CalendarDate.today() }).then( (measurements) => {
      this.table.measurements = measurements
    })
  }
}

export default HistoryView
