import { CalendarDate } from "@brutaljs/JunkDrawer"
import { CustomFormValidator } from "@brutaljs/Form"

export default class DateRangesMustBeInOrder extends CustomFormValidator {
  validate(formData) {
    const from = CalendarDate.fromDateOrStringOrUndefined(formData.get("dateFrom"))
    const to   = CalendarDate.fromDateOrStringOrUndefined(formData.get("dateTo"))
    if (!from) {
      return this.failed("From date is required")
    }
    if (!to) {
      return this.failed("To date is required")
    }

    if (to.isAfter(from)) {
      return this.failed("From date must be before To date")
    }

    return this.passed()
  }
}

