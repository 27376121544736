import { Component } from "@brutaljs"

export default class ErrorMessagePanel extends Component {
  showErrors(messages) {
    messages = [ messages ].flat()
    this.element.innerText = messages.join(", ")
    this.show()
    this.element.scrollIntoView()
  }
}

