import { Component }       from "@brutaljs"
import { TextPlaceholder } from "@brutaljs/Components"

export default class Notice extends Component {
  constructor(element) {
    super(element)
    this.mealName = new TextPlaceholder(this.$slot("mealName"))
  }

  show(meal) {
    this.mealName.text = meal.name
    super.show()
  }
  hide() {
    this.mealName.text = ""
    super.hide()
  }
}


