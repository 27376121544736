/** Makes handling a string containing a list of CSS classes a bit
 * easier to use.
 */
class CSSClassList {
  constructor(attributeValue) {
    attributeValue = (attributeValue || "").trim()
    if (attributeValue.length == 0) {
      this.cssClasses = []
    }
    else {
      this.cssClasses = attributeValue.split(/\s+/)
    }
  }

  isEmpty() { return this.cssClasses.length == 0 }

  addToElement(element) {
    this.cssClasses.forEach( (cssClass) => element.classList.add(cssClass) )
  }

  removeFromElement(element) {
    this.cssClasses.forEach( (cssClass) => element.classList.remove(cssClass) )
  }
}
export default CSSClassList
