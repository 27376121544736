import EphemeralMeasurement from "../DataTypes/EphemeralMeasurement"
import Meal                 from "../DataTypes/Meal"
import Metric               from "../DataTypes/Metric"
import BaseService          from "./BaseService"
import MealExistsError      from "./MealExistsError"
import SupabaseError        from "./SupabaseError"

export default class Meals extends BaseService {
  fetch() {
    this.methodStart("fetch")
    return this.supabase.client().from("meal_measurements").
      select("*, meals(*), metrics(*)").then( ({data,error} ) => {
        if (error) {
          return this.rejectMethod("fetch",new SupabaseError(error))
        }
        const meals = {}
          console.log(data)
        data.forEach( (row) => {
          const mealId = row.meal_id
          if (!meals[mealId]) {
            meals[mealId] = new Meal({id: mealId, userId: row.meals.user_id, name: row.meals.name, ephemeralMeasurements: []})
          }
          const metric = new Metric(row.metrics)
          const ephemeralMeasurement = new EphemeralMeasurement({ metric: metric, value: row.value })
          meals[mealId].ephemeralMeasurements.push(ephemeralMeasurement)
        })
        const sortedMeals = Array.from(Object.values(meals)).sort(Meal.compare)
        return this.resolveMethod("fetch",sortedMeals, { meals: sortedMeals })
    })
  }

  save(meal) {
    this.methodStart("save", { meal: meal })
    return this.supabase.client().from("meals").
      insert({ name: meal.name, user_id: meal.userId }).
      select().
      then( ({data,error}) => {
        if (error) {
          if (error.code == "23505") {
            return this.rejectMethod("save",new MealExistsError(error))
          }
          else {
            return this.rejectMethod("save",new SupabaseError(error))
          }
        }
        if (data.length != 1) {
          return this.rejectMethod("save", new Error(`Got ${data.length} records back but expected exactly 1`))
        }
        const mealId = data[0].id
        const mealMeasurementRows = meal.ephemeralMeasurements.map( (ephemeralMeasurement) => {
          return {
            meal_id: mealId,
            metric_id: ephemeralMeasurement.metric.id,
            value: ephemeralMeasurement.value
          }
        })
        return this.supabase.client().from("meal_measurements").insert(mealMeasurementRows).then( ( {error} ) => {
          if (error) {
            return this.rejectMethod("save", new SupabaseError(error))
          }
          return this.resolveMethod("save")
        })
      })
  }
}

