import Metric from "../DataTypes/Metric"
import SupabaseError from "./SupabaseError"
import BaseService from "./BaseService"

class InitialMetrics extends BaseService {
  constructor(supabase, userId) {
    super(supabase)
    this.userId = userId
  }

  createInitialMetrics() {
    this.methodStart("createInitialMetrics")
    return this.supabase.client().from("metrics").upsert(
      [
        { user_id: this.userId, name: "Fat",      units: "grams",  emoji: "🐷", goal_type: "at_most" },
        { user_id: this.userId, name: "Calories", units: "kcal",   emoji: "🔥", goal_type: "at_most" },
        { user_id: this.userId, name: "Drinks",   units: "drinks", emoji: "🍸", goal_type: "at_most" },
        { user_id: this.userId, name: "Fiber",    units: "grams",  emoji: "🥦", goal_type: "at_least" },
        { user_id: this.userId, name: "Salt",     units: "mg",     emoji: "🧂", goal_type: "at_most" },
      ],
      {
        ignoreDuplicates: false,
        onConflict: "name, user_id"
      }
    ).select().then( ({data,error}) => {
      if (error) {
        return this.rejectMethod("createInitialMetrics", new SupabaseError(error))
      }
      const metrics = data.map( (row) => {
        return new Metric(row)
      })
      return this.resolveMethod("createInitialMetrics", metrics, { metrics: metrics })
    })
  }
}
export default InitialMetrics
