import { FormObject } from "@brutaljs/Form"
import DateRangesMustBeInOrder from "./DateRangesMustBeInOrder"

export default class CSVExportFormObject extends FormObject {
  constructor() {
    super()
    this.field("dateFrom")
    this.field("dateTo")
    this.addCustomValidation(new DateRangesMustBeInOrder())
  }
}

