import Measurement from "./Measurement"
export default class Progress {
  constructor({numDays, goal, measurement}) {
    this.numDays     = numDays
    this.goal        = goal
    this.measurement = measurement
  }

  /** Returns the difference between goal and current value, with negative
   * meaning the goal is not reached/exceeded. Positive means good.
   */
  diff() {
    return this.averageOverPeriod() - this.goal.value
  }

  progressPercent() {
    return Math.round(100 * (this.averageOverPeriod()  / this.goal.value))
  }

  averageOverPeriod() { return this.measurement.value / this.numDays }

  status() {
    const percentDiff = this.diff() / this.goal.value

    let status

    this.goal.metric.goalType.whenAtMost( () => {
      if (percentDiff < 0) {
        status = "good"
      }
      else if (percentDiff < 0.05) {
        status = "close"
      }
      else {
        status = "bad"
      }
    }).whenAtLeast( () => {
      if (percentDiff >= 0) {
        status = "good"
      }
      else if (percentDiff > -0.05) {
        status = "close"
      }
      else {
        status = "bad"
      }
    })
    return status
  }

  static compare(a,b) {
    return Measurement.compare(a.measurement,b.measurement)
  }
}
