import { Form } from "@brutaljs/Form"

import GoalsTable from "./GoalsTable"

export default class GoalsForm extends Form {
  static logContext = "StellarTrack.Views"
  constructor(element) {
    super(element)
    this.proxySubmit()
    this.table = new GoalsTable(this.$("goals-table"))
  }

  addOrUpdateGoalInputRow(goal) {
    const newRow = this.table.addOrUpdateRow(goal)

    const input = this.$selector("input", newRow)
    const label = this.$selector("label", newRow)

    input.setAttribute("name",goal.metric.name)
    input.setAttribute("id",goal.metric.name)
    label.setAttribute("for",goal.metric.name)
    if (goal.value) {
      input.setAttribute("value",goal.value)
    }
  }
}

