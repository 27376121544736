import { Button } from "@brutaljs/Components"

export default class MarkDownloadedButton extends Button {
  constructor(element) {
    super(element)
    this.downloadDate = this.$slot("downloadDate")
  }
  set date(newDate) {
    this.dateToRemember = newDate
    this.downloadDate.innerHTML = this.dateToRemember.toString()
  }
}

