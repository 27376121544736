import { View, Runtime } from "@brutaljs"

/** Base view for all views in the app */
class BaseView extends View {
  static logContext = "StellarTrack.Views"
  static redirectTo() {
    if (!this.url) {
      return Promise.reject(`${this.name} has not set the static propery url so we cannot redirect`)
    }
    return new Promise( () => {
      if (Runtime.env().isDev()) {
        console.debug("Since this is dev, waiting 5s before redirecting")
        setTimeout( () => { window.location = this.url },5000)
      }
      else {
        window.location = this.url
      }
    })
  }
}
export default BaseView
