class HumanizedString {
  static for(string) { return new HumanizedString(string) }
  constructor(string) {
    string = `${string || ""}` // Force string coercion
    this.string = string[0].toUpperCase() + string.slice(1)
  }

  toString() {
    return this.string
  }
}
export default HumanizedString
