import { Component, Runtime } from "@brutaljs"
import Supabase               from "../../Services/Supabase"
import { Session }            from "../../Services/Auth"
import AuthView               from "../../Views/AuthView"

class SignOutLink extends Component {
  constructor(element) {
    super(element)
    this.session = new Session(new Supabase())

    this.element.removeAttribute("disabled")
    this.element.addEventListener("click", (event) => {
      event.preventDefault()
      this.session.logOut().then( () => {
        AuthView.redirectTo()
      })
    })
  }
}

export default SignOutLink
