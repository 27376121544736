class TypeOf {
  constructor(value) {
    this.typeName = typeof value
    try {
      if ( ( (typeof value) === "function") && value.name ) {
        this.typeName = value.name
      }
      else if (value && value.constructor && value.constructor.name) {
        this.typeName = value.constructor.name
      }
    }
    catch (error) {
      console.error(error)
    }
  }
  toString() { return this.typeName }

  static asString(value) {
    return new TypeOf(value).toString()
  }
}
export default TypeOf
