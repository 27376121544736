import { Component }    from "@brutaljs"
import { CSSClassList } from "@brutaljs/JunkDrawer"

export default class Bar extends Component {
  showProgress(progress) {
    const percentThere = progress.progressPercent()
    if (percentThere > 0) {
      this.element.style.width = `${Math.min(100,percentThere)}%`
      this.element.classList.add("ph2")
    }
    if (percentThere >= 100) {
      (new CSSClassList(this.element.dataset.cssWhenFull)).addToElement(this.element)
    }
    else {
      (new CSSClassList(this.element.dataset.cssWhenNotFull)).addToElement(this.element)
    }
    let cssClassList
    if (progress.status() == "good") {
      cssClassList = new CSSClassList(this.element.dataset.cssWhenGood)
    }
    else if (progress.status() == "bad") {
      cssClassList = new CSSClassList(this.element.dataset.cssWhenBad)
    }
    else {
      cssClassList = new CSSClassList(this.element.dataset.cssWhenClose)
    }
    cssClassList.addToElement(this.element)
  }
}
