import Measurement   from "../DataTypes/Measurement"
import BaseService   from "./BaseService"
import Metrics       from "./Metrics"
import SupabaseError from "./SupabaseError"

export default class MeasurementsPerDay extends BaseService {
  fetch(fromDate,toDate) {
    this.methodStart("fetch",{ fromDate: fromDate, toDate: toDate })
    if (!toDate) {
      toDate = fromDate
    }
    return this.supabase.client().from("measurements_per_day").
      select("*").
      gte("measured_on",fromDate.toString()).
      lte("measured_on",toDate.toString()).
      order("measured_on").
      then( ({data,error}) => {
        if (error) {
          return this.rejectMethod("fetch", new SupabaseError(error))
        }

        const measurements = data.map( (row) => {
          const metric = Metrics.fromRow(row)
          return new Measurement({value: row.value, measuredOn: row.measured_on, metric: metric})
        })

        return this.resolveMethod("fetch",measurements,{ measurements: measurements })
      })
  }
}
