import { Animator, Component } from "@brutaljs"
import { Button, TextPlaceholder } from "@brutaljs/Components"

class Nav extends Component {
  constructor(element) {
    super(element)
    this.appVersion = new TextPlaceholder(this.$slot("appVersion"))
    this.appVersion.text = APP_VERSION.slice(0,6)
    this.closeButton = new Button(this.$("close-nav"))
    this.closeButton.onClick( () => this.hide() )
    if (this.element.classList.contains("dn")) {
      this.element.style.transform="translateY(-100%)"
      this.element.classList.remove("dn")
      this.hidden = true
    }
    this.animator = new Animator(this.element,{
      duration: 250,
      styles: {
        transform: {
          from: "translateY(-100%)",
          to:   "translateY(0)",
        }
      }
    })
  }
}
export default Nav
