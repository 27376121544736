import { Component }    from "@brutaljs"
import { CSSClassList } from "@brutaljs/JunkDrawer"
import Bar from "./Bar"

export default class RunningAverageTable extends Component {
  static logContext = "StellarTrack"

  constructor(element) {
    super(element)
    this.tbody = this.$selector("tbody")
    this.rowTemplate = this.template()
  }
  addProgress(progress) {
    const measurement = progress.measurement
    const goal = progress.goal
    const numberFormat = Intl.NumberFormat(false,{ notation: "standard", }).format
    const numberFormatForDiff = Intl.NumberFormat(false,{ notation: "standard", signDisplay: "exceptZero" }).format

    const row = this.rowTemplate.newNode({
      fillSlots: {
        name: measurement.metric.name,
        emoji: measurement.metric.emoji,
        goalValue: numberFormat(goal.value),
        currentAverage: numberFormat(Math.round(progress.averageOverPeriod())),
        units: measurement.metric.units,
      }
    })
    const bar = new Bar(this.$("bar",row))
    bar.showProgress(progress)

    row.dataset.metric = goal.metric.name
    
    let cssClassList
    if (progress.status() == "good") {
      cssClassList = new CSSClassList(row.dataset.cssWhenGood)
    }
    else if (progress.status() == "bad") {
      cssClassList = new CSSClassList(row.dataset.cssWhenBad)
    }
    else {
      cssClassList = new CSSClassList(row.dataset.cssWhenClose)
    }
    cssClassList.addToElement(row)

    this.tbody.appendChild(row)
  }
}
