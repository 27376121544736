import { FormObject } from "@brutaljs/Form"

export default class GoalsFormObject extends FormObject {
  constructor(metrics) {
    super()
    metrics.forEach( (metric) => {
      this.field(metric.name)
    })
  }
}

