import { AtLeastOneFormValidator, FormObject } from "@brutaljs/Form"
export default class NewMealFormObject extends FormObject {
  constructor(metrics) {
    super()
    this.metrics = []
    metrics.forEach( (metric) => {
      this.field(metric.name)
      this.metrics.push(metric)
    })
    this.field("name")
    this.addCustomValidation(new AtLeastOneFormValidator(this.metrics.map( (metric) => metric.name )))
  }
}

