import { createClient } from "@supabase/supabase-js"
import { Runtime } from "@brutaljs"

class Supabase {

  static CONFIG = {
    production: {
      projectURL: "https://knkbndpwlrerwbzmjtnz.supabase.co",
      publicAnonKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imtua2JuZHB3bHJlcndiem1qdG56Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzA3MDcyOTEsImV4cCI6MTk4NjI4MzI5MX0.t0EoMCR2duk-TwtaKrFPr6Rlox24erH0MxiumigAGig"
    },
    dev: {
      projectURL: "http://localhost:54321",
      publicAnonKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0"
    },
    test: {
      projectURL: "http://localhost:54321",
      publicAnonKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0"
    }
  }

  constructor() {
    const config = Supabase.CONFIG[Runtime.env().toString()]
    this.supabase = createClient(config.projectURL, config.publicAnonKey)
  }

  client() { return this.supabase }
}

export default Supabase
