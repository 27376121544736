import { Form } from "@brutaljs/Form"
import SearchFormObject from "./SearchFormObject"

export default class SearchForm extends Form {
  constructor(element) {
    super(element, new SearchFormObject())
    this.qField = this.$selector("input[name='q']")
  }

  formSubmitted(event,formObject) {
    this.qField.value = formObject.q
    this.element.submit()
  }
}
