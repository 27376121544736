import { CalendarDate } from "@brutaljs/JunkDrawer"

import BaseView           from "./BaseView"
import MeasurementsPerDay from "../Services/MeasurementsPerDay"
import Supabase           from "../Services/Supabase"
import User               from "../Services/Auth/User"
import FormErrors         from "../Components/FormErrors"
import CSVExportForm      from "./CSVExportView/CSVExportForm"
import Download           from "./CSVExportView/Download"


export default class CSVExportView extends BaseView {

  static url = "/csv-export.html"

  constructor(element) {
    super(element)
    this.supabase = new Supabase()
    this.measurementsPerDay = new MeasurementsPerDay(this.supabase)
    this.user = new User(this.supabase)
    this.form = new CSVExportForm(this.$("csv-form"))
    this.formErrors = new FormErrors(this.$("form-errors"))
    this.download = new Download(this.$("download"))
    this.form.onValidationErrors( (errors) => {
      this.download.hide()
      this.formErrors.showErrors(errors)
    })
    this.form.onSubmit( (formObject) => {
      this.download.hide()
      this.formErrors.hide()
      this.measurementsPerDay.fetch(formObject.dateFrom, formObject.dateTo).then( (measurements) => {
        const data = {}
        measurements.map( (measurement) => {
          if (!data[measurement.measuredOn]) {
            data[measurement.measuredOn] = {}
          }
          data[measurement.measuredOn][measurement.metric.name]= measurement.value
        })
        const csvData = [
          [ "Date", "Energy Consumed", "Total Fat", "Fiber", "Sodium", "Alcohol Consumption" ],
        ]
        Object.keys(data).forEach( (measuredOn) => {
          const row = [
            measuredOn
          ]
          row.push(data[measuredOn]["Calories"] || 0)
          row.push(data[measuredOn]["Fat"] || 0)
          row.push(data[measuredOn]["Fiber"] || 0)
          row.push(data[measuredOn]["Salt"] || 0)
          row.push(data[measuredOn]["Drinks"] || 0)
          csvData.push(row)
        })
        this.download.setCSV({
          from: formObject.dateFrom,
          to: formObject.dateTo,
          data: csvData,
        })
      })
    })
  }

  prepareView(user) {
    const setDefaultOneWeek = () => {
      this.form.setDefaults({
        from: CalendarDate.daysAgo(7),
        to: CalendarDate.daysAgo(1)
      })
    }

    if (user.csv_export_last_measured_on) {
      try {
        this.form.setDefaults({
          from: new CalendarDate(user.csv_export_last_measured_on),
          to: CalendarDate.today(),
        })
      }
      catch (e) {
        console.error(`While setting defaults: ${e}`)
        setDefaultOneWeek()
      }
    }
    else {
      setDefaultOneWeek()
    }
    this.download.onMarkDownloaded( (date) => {
      this.user.rememberCsvExportLastMeasuredOn(user, date).then( () => {
        this.download.remembered()
      })
    })
    return Promise.resolve()
  }

}
