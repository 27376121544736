import { CalendarDate } from "@brutaljs/JunkDrawer"

import EphemeralMeasurement from "../DataTypes/EphemeralMeasurement"
import FormErrors           from "../Components/FormErrors"
import Measurements         from "../Services/Measurements"
import Meal                 from "../DataTypes/Meal"
import Meals                from "../Services/Meals"
import MealExistsError      from "../Services/MealExistsError"
import Session              from "../Services/Auth/Session"
import BaseView             from "./BaseView"
import Supabase             from "../Services/Supabase"

import AddNewMealButton  from "./MealsView/AddNewMealButton"
import DateSelector      from "./MealsView/DateSelector"
import MealsList         from "./MealsView/MealsList"
import NewMealForm       from "./MealsView/NewMealForm"
import Notice            from "./MealsView/Notice"

class MealsView extends BaseView {

  static url = "/meals.html"

  prepareView(user) {
    this.userId = user.id
    return Promise.resolve()
  }

  constructor(element) {
    super(element)

    const supabase = new Supabase()

    const meals              = new Meals(supabase)
    const measurements       = new Measurements(supabase)

    const addNewMealButton   = new AddNewMealButton(this.$("add-new-meal-button"))
    const addNewMealForm     = new NewMealForm(this.$("add-new-meal-form"), supabase)

    const mealsList          = new MealsList(this.$("meals-list"), meals)
    const dateSelector       = new DateSelector(this.$("date-selector"))

    const formErrors         = new FormErrors(this.$("form-errors"))
    const notice             = new Notice(this.$("notice"))

    addNewMealButton.onClick( () => addNewMealForm.toggle() )
    addNewMealButton.onClick( () => mealsList.toggle() )
    addNewMealButton.onClick( () => dateSelector.toggle() )
    addNewMealButton.onClick( () => notice.hide() )

    addNewMealForm.onNevermind( () => dateSelector.show() )
    addNewMealForm.onNevermind( () => mealsList.show() )
    addNewMealForm.onNevermind( () => addNewMealButton.show() )
    addNewMealForm.onNevermind( () => addNewMealForm.hide() )

    mealsList.onMealRecorded( (meal) => {
      notice.hide()
      const date = dateSelector.getDate() || CalendarDate.today().toString()
      measurements.saveMeal(meal, date).then( () => {
        notice.show(meal)
      })
    })

    addNewMealForm.onValidationErrors( (formValidationErrors) => {
      formErrors.showErrors(formValidationErrors)
    })

    addNewMealForm.onSubmit( (formObject) => {
      const measurements = []
      formObject.metrics.forEach( (metric) => {
        const value = formObject[metric.name]
        if (value) {
          measurements.push(
            new EphemeralMeasurement({
              metric: metric,
              value: value
            })
          )
        }
      })
      const meal = new Meal({ userId: this.userId, name: formObject.name, ephemeralMeasurements: measurements})
      meals.save(meal).then( () => {
        mealsList.refresh().then( () => {
          dateSelector.show()
          mealsList.show()
          addNewMealButton.show()
          addNewMealForm.hide()
        })
        addNewMealForm.reset()
      }).catch( (error) => {
        if (error instanceof MealExistsError) {
          formErrors.showErrors([ error.message ])
          return Promise.resolve()
        }
        else {
          return Promise.reject(error)
        }
      })
    })
  }
}

export default MealsView
