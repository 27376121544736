import Metric from "../DataTypes/Metric"
import InitialMetrics from "./InitialMetrics"
import BaseService from "./BaseService"
import SupabaseError from "./SupabaseError"

export default class Metrics extends BaseService {
  static fields() { return [ "id", "name", "units", "emoji", "goalType:goal_type" ].join(", ") }
  static fromRow(row) { return new Metric(row) }

  fetch() {
    this.methodStart("fetch")
    return this.supabase.client().from("metrics").
      select(Metrics.fields()).
      order("name", { ascending: true }).
      then( ({data, error}) => {
        if (error) {
          return this.rejectMethod("fetch", new SupabaseError(error))
        }

        const metrics = data.map( (row) => {
          return Metrics.fromRow(row)
        })

        if (metrics.length == 0) {
          this.methodDone("fetch")
          return this.initialize()
        }
        return this.resolveMethod("fetch", metrics, { metrics: metrics })
      })
  }

  initialize(userId) {
    const initialMetrics = new InitialMetrics(this.supabase, userId)
    return initialMetrics.createInitialMetrics()
  }
}
