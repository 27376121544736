import { Component, EventManager } from "@brutaljs"
import MealRow                     from "./MealRow"

export default class MealsList extends Component {
  constructor(element, meals) {
    super(element)
    EventManager.defineEvents(this,"mealRecorded")
    this.mealTemplate = this.template()
    this.meals = meals
    this.mealRows = []
    this.refresh()
  }

  refresh() {
    return this.meals.fetch().then( (mealsList) => {
      this.mealRows.forEach( (mealRow) => {
        mealRow.element.remove()
      })
      mealsList.forEach( (meal) => {
        const mealRow = new MealRow(this.mealTemplate.newNode({
          fillSlots: {
            name: meal.name
          }
        }), meal)
        mealRow.element.ariaLabel = meal.name
        this.mealRows.push(mealRow)
        this.element.appendChild(mealRow.element)
        mealRow.onMealRecorded( (meal) => this.mealRecordedEventManager.fireEvent(meal) )
      })
    })
  }
}

