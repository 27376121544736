import { Component } from "@brutaljs"

import FormErrors      from "../Components/FormErrors"
import MeasurementForm from "./RecordView/MeasurementForm"
import Measurements    from "../Services/Measurements"
import Metrics         from "../Services/Metrics"
import SearchForm      from "./RecordView/SearchForm"
import BaseView        from "./BaseView"
import Supabase        from "../Services/Supabase"

const Notice = Component

class RecordView extends BaseView {

  static url = "/record.html"

  constructor(element) {
    super(element)
    this.supabase        = new Supabase()
    this.measurementForm = new MeasurementForm(this.$("measurement-form"))
    this.searchForm      = new SearchForm(this.$("search"))
    this.formErrors      = new FormErrors(this.$("form-errors"))
    this.notice          = new Notice(this.$("form-notice"))
    this.userMetrics     = []
    this.metrics         = new Metrics(this.supabase)
    this.measurements    = new Measurements(this.supabase)

    this.measurementForm.onValidationErrors( (validationErrors) => {
      this.notice.hide()
      this.formErrors.showErrors(validationErrors)
    })

    this.measurementForm.onSubmit( (formObject) => {
      this.formErrors.hide()
      this.notice.hide()

      const measuredOn = formObject.measuredOn

      const insert = []

      this.userMetrics.forEach( (metric) => {
        const value = formObject[metric.name]
        if (value) {
          insert.push({
            metric: metric,
            value: value,
            measuredOn: measuredOn
          })
        }
      })
      this.measurements.save(insert).then( () => {
        this.notice.show()
        this.measurementForm.reset()
      })
    })
  }

  prepareView() {
    return this.metrics.fetch().then( (userMetrics) => {
      if (userMetrics.length == 0) {
        return Promise.reject("There were no metrics for the user")
      }
      this.userMetrics = userMetrics
      this.measurementForm.createFields(userMetrics)
    })
  }
}
export default RecordView
