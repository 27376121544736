import { Component, EventManager } from "@brutaljs"

export default class AddNewMealButton extends Component {
  constructor(element) {
    super(element)
    EventManager.createDirectProxyFor(
      this,
      {
        element: this.element,
        eventName: "click"
      })
  }
}

