import TypeOf from "./TypeOf"

/** Represents a date, not a timestamp, but since Date was taken, here we are */
class CalendarDate {
  static today() {
    return CalendarDate.daysAgo(0)
  }
  static daysAgo(days) {
    const date = new Date()
    date.setDate(date.getDate() - days)
    return new CalendarDate(date)
  }
  static fromDateOrStringOrUndefined(dateOrString) {
    if (dateOrString) {
      return new CalendarDate(dateOrString)
    }
    else {
      return undefined
    }
  }

  constructor(dateOrString) {
    let date = dateOrString
    if ( TypeOf.asString(dateOrString) == "String" ) {
      const parts = dateOrString.split("-")
      if (parts.length != 3) {
        throw `CalendarDate requires a Date or String in the form YYYY-MM-DD, but got '${dateOrString}'`
      }
      date = new Date(parts[0], parts[1] - 1, parts[2])
    }
    if (!(date instanceof Date)) {
      throw `CalendarDate requires a Date, but got ${new TypeOf(date)} ('${date}')`
    }

    this.date  = date
    this.year  = date.getFullYear()
    this.month = date.getMonth() + 1
    this.day   = date.getDate()
    this.MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000

    this.string = `${this.year}-${this._zeroPad(this.month)}-${this._zeroPad(this.day)}`
  }

  isSameDayAs(otherCalendarDate) {
    if (!(otherCalendarDate instanceof CalendarDate)) {
      return false
    }
    return (this.year  === otherCalendarDate.year) &&
      (this.month === otherCalendarDate.month) &&
      (this.day   === otherCalendarDate.day)
  }

  daysDiff(otherCalendarDate) {

    const usUTC    = this.asUTC()
    const otherUTC = otherCalendarDate.asUTC()

    return ( otherUTC.date - usUTC.date) / this.MILLISECONDS_PER_DAY
  }

  isAfter(otherCalendarDate) {
    return this.daysDiff(otherCalendarDate) > 0
  }

  _zeroPad(number) {
    if (number > 9) {
      return `${number}`
    }
    else {
      return `0${number}`
    }
  }

  toString()    { return this.string }

  asUTC() {
    const inUTC = new Date(this.date);
    inUTC.setMinutes(inUTC.getMinutes() - inUTC.getTimezoneOffset());
    return new CalendarDate(inUTC);
  }
}
export default CalendarDate
